import { LIST_BANK, LIST_VISA, OnepayMethodEnum, PaymentMethod, PaymentMethodKey, paymentQR } from '@utils/constan'
import { Partner } from '..'
import { BillType, IPaymentMethod } from '../../../types/general'

interface IProps {
  partner: Partner[]
  bills: BillType[]
  paymentMethod: IPaymentMethod[]
}

const useRenderMethod = ({ partner, bills, paymentMethod }: IProps) => {
  const handleMapDescription = (title: string) => {
    switch (title) {
      case PaymentMethodKey.WALLET:
        return 'Chọn đơn vị thu'
      case PaymentMethodKey.VISA:
        return 'Visa, Mastercard'
      case PaymentMethodKey.ATM:
        return 'Chọn đơn vị thu'
      case PaymentMethodKey.QR:
        return 'Chọn đơn vị thu'

      default:
        return title
    }
  }

  const handleMapImg = (title: string) => {
    switch (title) {
      case PaymentMethodKey.WALLET:
        return PaymentMethodKey.VNMART
      case PaymentMethodKey.VISA:
        return PaymentMethodKey.VISA
      case PaymentMethodKey.ATM:
        return PaymentMethodKey.ATM
      case PaymentMethodKey.QR:
        return PaymentMethodKey.QR

      default:
        return PaymentMethodKey.ATM
    }
  }

  const handleMapPaymentsTitle = (title: string) => {
    switch (title) {
      case PaymentMethodKey.WALLET:
        return ''
      case PaymentMethodKey.VISA:
        return 'Visa, Mastercard, JCB...'
      case PaymentMethodKey.ATM:
        return 'Ngân hàng trong nước'
      case PaymentMethodKey.QR:
        return ''

      default:
        return title
    }
  }

  const handleMapPayments = (title: string) => {
    let filteredPaymentQR = paymentQR.filter((qr) => {
      const mewPartner = partner.find((p) => p.partnerCode === qr.key && p.status === 1)
      return mewPartner
    })

    filteredPaymentQR = [{ ...paymentQR[0] }, ...filteredPaymentQR]

    switch (title) {
      case PaymentMethodKey.WALLET:
        return [{ icon: 'VNPAY2', title: 'Ví VNPAY', id: PaymentMethodKey.VNMART }]
      case PaymentMethodKey.VISA:
        return LIST_VISA
      case PaymentMethodKey.ATM:
        return LIST_BANK
      case PaymentMethodKey.QR:
        return filteredPaymentQR.map((e) => ({
          icon: e.key,
          title: e.title,
          id: e.key,
        }))

      default:
        return [{ icon: PaymentMethodKey.VNPAYQR, title: 'VNPAY QR', id: PaymentMethodKey.VNPAYQR }]
    }
  }

  const handleHiddenPaymentMethod = (id: string) => {
    const sumBills = bills.reduce((a, b) => (a += b.phai_thu), 0)
    const itemPaymentMethod = paymentMethod.find((e) => e._id === id)

    switch (itemPaymentMethod?.sign) {
      case 'any':
        return true

      case '>':
        return sumBills > itemPaymentMethod.amount

      case '<':
        return sumBills < itemPaymentMethod.amount

      case '>=':
        return sumBills >= itemPaymentMethod.amount

      case '<=':
        return sumBills <= itemPaymentMethod.amount

      case '=':
        return sumBills === itemPaymentMethod.amount

      default:
        return true
    }
  }

  const handleMapChild = (id: string) => {
    if (id === PaymentMethodKey.QR) {
      let filteredPayment = PaymentMethod.filter((qr) => {
        const mewPartner = partner.find((p) => p.partnerCode === qr.id && p.status === 1)
        return mewPartner
      })
      const VNPAY = partner.find((e) => e.partnerCode === PaymentMethodKey.VNPAY && e.status === 1)
      filteredPayment = VNPAY ? [{ ...PaymentMethod[0] }, ...filteredPayment] : [...filteredPayment]

      return filteredPayment.map((el) => ({
        description: '',
        icon: el.id,
        id: el.id,
        title: el.name,
        status: true,
        type: el.type,
      }))
    } else if (id !== PaymentMethodKey.WALLET) {
      const paymentMethod = [
        {
          description: '',
          icon: PaymentMethodKey.VNPAY,
          id: id === PaymentMethodKey.QR ? PaymentMethodKey.VNPAYQR : id,
          title: PaymentMethodKey.VNPAY,
          hidden: !partner.find((e) => e.status === 1 && e.partnerCode === PaymentMethodKey.VNPAY),
        },

        {
          description: '',
          icon: PaymentMethodKey.ONEPAY,
          id: PaymentMethodKey.ONEPAY,
          title: 'Onepay',
          status: true,
          type: id === PaymentMethodKey.VISA ? OnepayMethodEnum.INTERNATIONAL : OnepayMethodEnum.DOMESTIC,
          hidden: !partner.find((e) => e.status === 1 && e.partnerCode === PaymentMethodKey.ONEPAY),
        },
        {
          description: '',
          icon: PaymentMethodKey.SHB,
          id: PaymentMethodKey.SHB,
          title: PaymentMethodKey.SHB,
          status: true,
          hidden: !partner.find((e) => e.status === 1 && e.partnerCode === PaymentMethodKey.SHB),
        },
        {
          description: '',
          icon: PaymentMethodKey.VIETINBANK,
          id: PaymentMethodKey.VIETINBANK,
          title: 'Vietinbank',
          status: true,
          hidden: !partner.find((e) => e.status === 1 && e.partnerCode === PaymentMethodKey.VIETINBANK),
        },
      ]

      return paymentMethod.flatMap((e) => (e.hidden ? [] : e))
    } else {
      return [
        {
          description: '',
          icon: PaymentMethodKey.VNMART,
          id: PaymentMethodKey.VNMART,
          title: PaymentMethodKey.VNPAY,
          status: true,
          hidden: !partner.find((e) => e.status === 1 && e.partnerCode === PaymentMethodKey.VNPAY),
        },
      ].flatMap((e) => (e.hidden ? [] : e))
    }
  }

  return {
    handleMapImg,
    handleMapChild,
    handleMapPayments,
    handleMapDescription,
    handleMapPaymentsTitle,
    handleHiddenPaymentMethod,
  }
}

export default useRenderMethod
