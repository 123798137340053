import SocketCalling from '@hooks/SocketCalling'
import { API_URL, NetWorkService, validResponse } from '@utils/API'
import { CODE_SUCCESS, LIST_BANK, LIST_VISA, PaymentMethodKey } from '@utils/constan'
import { detectEnvUat, detectWebview } from '@utils/general'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { setDevice, setEduBillDetail, setLoading, setStudenInfor, Type_Initstate } from 'store/appSlice'
import {
  BillType,
  IPaymentMethod,
  IPaymentMethodResponse,
  IResponeICB,
  ItemBillResponse,
  TypeMethodPayment,
} from '../../types/general'
import View from './components/View'
import useRenderMethod from './hooks/useRenderMethod'

export interface Partner {
  partnerCode: string
  partnerName: string
  status: number
}

const ConfirmPayment = () => {
  const { Get, Post } = NetWorkService
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [payment, setPayment] = useState('')
  const [napas, setNapas] = useState(LIST_BANK)
  const [imgSHB, setImgSHB] = useState('')
  const [imgICB, setImgICB] = useState<IResponeICB | undefined>(undefined)
  const [imgACB, setImgACB] = useState('')
  const [imgBidv, setImgBidv] = useState('')
  const [visiable, setVisible] = useState(false)
  const [visiablesSHB, setVisibleSHB] = useState(false)
  const [visiablesICB, setVisibleICB] = useState(false)
  const [visiablesACB, setVisibleACB] = useState(false)
  const [bills, setBills] = useState<BillType[]>([])
  const [toastMsgPTTT, setToastMsgPTTT] = useState(false)
  const [toastMsgHTTT, setToastMsgHTTT] = useState(false)
  const [tien_toi_thieu, set_tien_toi_thieu] = useState(0)
  const [listMethod, setListMethod] = useState<TypeMethodPayment[]>([])
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod[]>([])
  const [partner, setPartner] = useState<Partner[]>([])
  const [child, setChild] = useState<TypeMethodPayment | undefined>(undefined)
  const [transactionCode, setTransactionCode] = useState('')

  const [itemPaymentMethod, setItemPaymentMethod] = useState<TypeMethodPayment | undefined>(undefined)
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)

  const {
    handleMapImg,
    handleMapChild,
    handleMapPayments,
    handleMapDescription,
    handleMapPaymentsTitle,
    handleHiddenPaymentMethod,
  } = useRenderMethod({ bills, partner, paymentMethod })

  const handleCreateBill = async () => {
    try {
      !itemPaymentMethod && setToastMsgPTTT(true)
      !payment && setToastMsgHTTT(true)
      const defaultValue = 0

      let amountMin = bills.find((e) => e.tien_toi_thieu)
        ? bills.find((e) => e.tien_toi_thieu)?.tien_toi_thieu
        : defaultValue

      const amountMax = bills?.reduce((a, b) => a + +b.phai_thu, 0)

      if (bills.some((e) => e.is_bat_buoc_thanh_toan_het)) {
        amountMin = bills.reduce((a, b) => {
          if (b.is_bat_buoc_thanh_toan_het) {
            a += b.phai_thu
          }
          return a
        }, 0)
      }

      if (payment) {
        // eslint-disable-next-line
        let body: any = {
          ma_sv: appState.studenInfor.MaSV,
          bankCode: payment,
          so_phieu_bao: bills.map((e) => e.so_phieu_bao),
          is_nhap_tien: false,
        }

        if (tien_toi_thieu && tien_toi_thieu < amountMax) {
          body = { ...body, is_nhap_tien: true, amount: tien_toi_thieu }
        }

        if (bills.every((e) => e?.is_nhap_tien)) {
          if (tien_toi_thieu < amountMin || tien_toi_thieu > amountMax) {
            return false
          }

          if (tien_toi_thieu > amountMin && tien_toi_thieu < amountMax && tien_toi_thieu % 1000) {
            return false
          }
        }

        dispatch(setLoading(true))
        let res

        if (child.id === PaymentMethodKey.BIDV) {
          res = await Post({
            url: `/api/organization/${params.orgCode}/school/${params.schoolCode}/studentCode/${appState?.studenInfor?.MaSV}/payment/bidv`,
            body: {
              billIds: bills.map((e) => e.so_phieu_bao),
              userCustomAmount: body.is_nhap_tien,
              amount: body.is_nhap_tien ? body?.amount : 0,
            },
          })
        } else if (child.id === PaymentMethodKey.VIETINBANK) {
          res = await Post({
            url: `/payment/organization/${params.orgCode}/school/${params.schoolCode}/payment/vietinbank`,
            body: {
              ma_sv: body.ma_sv,
              is_nhap_tien: body.is_nhap_tien,
              bankCode: PaymentMethodKey.VIETINBANK,
              amount: body.is_nhap_tien ? body?.amount : undefined,
              so_phieu_bao: bills.map((e) => e.so_phieu_bao),
            },
          })
        } else if (child.id === PaymentMethodKey.ONEPAY) {
          const returnUrl =
            window.location.origin +
            `${detectEnvUat() ? '/uat' : ''}/payment-result/onepay/organization/${params.orgCode}/school/${
              params.schoolCode
            }/student/` +
            window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]

          res = await Post({
            url: `/api/organization/${params.orgCode}/school/${params.schoolCode}/studentCode/${appState?.studenInfor?.MaSV}/payment/onepay`,
            body: {
              billCodes: bills.map((e) => e.so_phieu_bao),
              userCustomAmount: body.is_nhap_tien,
              amount: body.is_nhap_tien ? body?.amount : 0,
              returnUrl,
              currUrl: returnUrl,
              method: child.type,
            },
          })
        } else if (child.id === PaymentMethodKey.SHB) {
          res = await Post({
            url: `/payment/organization/${params.orgCode}/school/${params.schoolCode}/payment/shb`,
            body,
          })
        } else if (child.id === PaymentMethodKey.ACB) {
          const dataPost = {
            billCodes: bills.map((e) => e.so_phieu_bao),
            userCustomAmount: body.is_nhap_tien,
            amount: body.is_nhap_tien ? body?.amount : 0,
          }

          res = await fetch(
            `${API_URL}/api/organization/${params.orgCode}/school/${params.schoolCode}/studentCode/${appState?.studenInfor?.MaSV}/payment/acb`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', // Important to specify the content type as JSON
              },
              body: JSON.stringify(dataPost),
            },
          ).then((res) => res.json())
        } else {
          res = await Post({
            url: `/payment/organization/${params.orgCode}/school/${params.schoolCode}/payment/vnpay`,
            body,
          })
        }

        if (child.id === PaymentMethodKey.ACB && res?.success !== false && res?.code === 200) {
          setImgACB(res.data.qrLink)
          setVisibleACB(true)
        } else if (res && validResponse(res) && res.data?.success !== false && res.data?.data?.success !== false) {
          if (child.id === PaymentMethodKey.BIDV) {
            setImgBidv(res.data.data.vietQRImage)
            setVisible(true)
            setTransactionCode(res.data.data.transactionCode)
          } else if (child.id === PaymentMethodKey.ONEPAY) {
            window.location.replace(`${res.data.data.redirectUrl}`)
          } else if (child.id === PaymentMethodKey.SHB) {
            setImgSHB(res.data.data.vietQRImage)
            setVisibleSHB(true)
          } else if (child.id === PaymentMethodKey.VIETINBANK) {
            setImgICB(res.data.data)
            setTransactionCode(res.data.data.transactionCode)
            setVisibleICB(true)
          } else {
            window.location.replace(`${res.data}`)
          }
        } else {
          message.error(
            res?.data?.data?.message ? res?.data?.data?.message : res.message ? res.message : 'Có lỗi xảy ra',
          )
        }
      }
    } catch (error) {
      console.log(error)
      message.error(error?.data?.data?.message ?? 'Có lỗi xảy ra')

      // navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleGetBillList = async () => {
    try {
      dispatch(setLoading(true))
      const index = window.location.href.split('/').length
      let secretCode = window.location.href.split('/')[index - 1]
      const isWebview = detectWebview().Webview
      if (secretCode.split('?').length > 1) {
        secretCode = secretCode.split('?')[secretCode.split('?').length - 2]
      }
      dispatch(setDevice(isWebview === 'Yes' ? 'app' : 'web'))
      const url = `/api/organization/${params.orgCode}/school/${params.schoolCode}/secretCode/${secretCode}/bill`
      // eslint-disable-next-line
      const res: any = await Get<ItemBillResponse>({
        url,
      })
      const { records } = res.data.data.data
      if (res && validResponse(res) && res.code === CODE_SUCCESS && res.data.data.success !== false) {
        dispatch(setStudenInfor(res.data.data.student))
        if (records?.every((e) => !e?.is_aq_mobile)) {
          handleCheckLocationState()
          if (location.state) {
            const listSoPhieuBao = location.state.currentValue
            setBills(listSoPhieuBao)
          }
        } else {
          setBills(records)
        }
      } else {
        message.error(res.data.data.message)

        navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
      }
    } catch (error) {
      console.log(error)

      navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleGetPaymentMethod = async () => {
    try {
      const res = await Get<IPaymentMethodResponse>({
        url: `/api/school/${params.schoolCode}/paymentMethod/detail`,
      })
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        if (!res.data?.data?.paymentMethod || res.data?.data?.paymentMethod.length === 0) {
          message.error('Chưa thiết lập phương thức thanh toán. Vui lòng liên hệ đóng trực tiếp tại nhà trường!')
        }

        setPaymentMethod(res.data?.data?.paymentMethod ?? [])
      } else {
        res && validResponse(res) && message.error(res.data.data.message)
        navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
      }
    } catch (error) {
      console.log(error)

      message.error('Có lỗi xảy ra')
      navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
    }
  }

  const handleGetPartners = async () => {
    try {
      const res: any = await Get({
        url: `api/organization/${params.orgCode}/school/${params.schoolCode}/partner/list`,
      })
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        setPartner(res.data.data.partners)
      } else {
        res && validResponse(res) && message.error(res.data.data.message)
      }
    } catch (error) {
      message.error('Có lỗi xảy ra')
      console.log(error)
    }
  }

  useEffect(() => {
    payment && setToastMsgHTTT(false)
  }, [payment])
  useEffect(() => {
    setToastMsgHTTT(false)
  }, [itemPaymentMethod])

  useEffect(() => {
    itemPaymentMethod && setToastMsgPTTT(false)

    if (itemPaymentMethod) {
      const priorityChoose = itemPaymentMethod.child.find((e) => itemPaymentMethod.priorityChoose === e.id)
      setChild(priorityChoose ? priorityChoose : itemPaymentMethod.child[0])
    }
  }, [itemPaymentMethod])

  const handleCheckLocationState = () => {
    if (!location.state) {
      navigate(
        `${detectEnvUat() ? '/uat' : ''}/organization/${params.orgCode}/school/${params.schoolCode}/student/${
          params.studentID
        }`,
      )
    }
  }

  useEffect(() => {
    if (child && child.id !== PaymentMethodKey.ATM && child.id !== PaymentMethodKey.VISA) {
      setPayment(`${child.id}`)
    } else if (child && child.id === PaymentMethodKey.ATM) {
      setPayment(napas[0].code)
    } else if (child && child.id === PaymentMethodKey.VISA) {
      setPayment(LIST_VISA[0].code)
    }
  }, [child])

  useEffect(() => {
    handleGetPaymentMethod()
    setNapas(LIST_BANK)
    // handleCheckLocationState()
    handleGetBillList()
    handleGetPartners()
  }, [])

  useEffect(() => {
    itemPaymentMethod && itemPaymentMethod.id === 'NAPAS' && setPayment('')
  }, [itemPaymentMethod])

  const handleSetId = (id: string) => {
    switch (id) {
      case PaymentMethodKey.QR:
        return PaymentMethodKey.VNPAYQR

      case PaymentMethodKey.WALLET:
        return PaymentMethodKey.VNMART

      default:
        return id
    }
  }

  const handleSetListMethod = () => {
    paymentMethod &&
      setListMethod(
        paymentMethod.map((e) => ({
          description: handleMapDescription(e._id),
          icon: handleMapImg(e._id),
          id: handleSetId(e._id),
          title: e.name,
          priorityChoose: e.priorityChoose,
          status: e.status ? true : false,
          paymentsTitle: handleMapPaymentsTitle(e._id),
          payments: handleMapPayments(e._id),
          sign: handleHiddenPaymentMethod(e._id),
          child: handleMapChild(e._id),
        })),
      )
  }

  useEffect(() => {
    handleSetListMethod()
  }, [paymentMethod, partner])

  useEffect(() => {
    listMethod.length && setChild(listMethod[0].child[0])
    listMethod.length && setItemPaymentMethod(listMethod[0])
  }, [listMethod])

  useEffect(() => {
    // setPayment('')
    if (itemPaymentMethod && itemPaymentMethod?.id === PaymentMethodKey.WALLET) {
      setPayment(PaymentMethodKey.VNMART)
    }
  }, [itemPaymentMethod])

  useEffect(() => {
    if (appState?.listEdubillDetail.length !== 0) {
      const edubillDetail = appState?.listEdubillDetail.find((e) => e.orgCode === params.orgCode)
      dispatch(setEduBillDetail(edubillDetail))
    }
  }, [appState?.listEdubillDetail, params.orgCode])

  return (
    <>
      <View
        appState={appState}
        payment={payment}
        bills={bills}
        child={child}
        handleCreateBill={handleCreateBill}
        imgBidv={imgBidv}
        itemPaymentMethod={itemPaymentMethod}
        listMethod={listMethod}
        napas={napas}
        setChild={setChild}
        setItemPaymentMethod={setItemPaymentMethod}
        setPayment={setPayment}
        setVisible={setVisible}
        set_tien_toi_thieu={set_tien_toi_thieu}
        tien_toi_thieu={tien_toi_thieu}
        toastMsgHTTT={toastMsgHTTT}
        toastMsgPTTT={toastMsgPTTT}
        visiable={visiable}
        imgShb={imgSHB}
        imgACB={imgACB}
        imgICB={imgICB}
        setVisibleShb={setVisibleSHB}
        setVisibleICB={setVisibleICB}
        setVisibleACB={setVisibleACB}
        visiableShb={visiablesSHB}
        visiableACB={visiablesACB}
        visiableICB={visiablesICB}
        paymentMethod={paymentMethod}
      />
      <SocketCalling transactionCode={transactionCode} />
    </>
  )
}

export default ConfirmPayment
