import IconCancel from '@pages/Payment/Components/IconCancel'
import { detectEnvUat, formatMoney } from '@utils/general'
import { Modal } from 'antd'
import React, { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IResponeICB } from '../../../../types/general'

interface IProps {
  img: IResponeICB | undefined
  isModalOpen: boolean
  allowCancel?: boolean
  setIsModalOpen: (value: boolean) => void
}

const ModaICB: FC<IProps> = ({ isModalOpen, img, allowCancel, setIsModalOpen }: IProps) => {
  const navigate = useNavigate()
  const params = useParams()

  const handleNavigate = () => {
    navigate(
      `${detectEnvUat() ? '/uat' : ''}/organization/${params.orgCode}/school/${params.schoolCode}/student/${
        params.studentID
      }`,
    )
  }

  const handleCancel = () => {
    if (allowCancel) {
      setIsModalOpen(false)
    } else if (window.confirm('Phiếu thu chỉ có thể tạo lại sau 15 phút. Bạn có muốn thoát !')) {
      setIsModalOpen(false)
      handleNavigate()
    }
  }

  useEffect(() => {
    let timeout

    if (isModalOpen) {
      timeout = setTimeout(() => {
        handleNavigate()
      }, 900000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isModalOpen])

  const beforeUnLoad = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.returnValue = 'akdjlasjdkasjdlaksj'
  }

  useEffect(() => {
    if (isModalOpen) {
      window.addEventListener('beforeunload', beforeUnLoad)
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad)
    }
  }, [isModalOpen])

  return (
    <>
      <Modal title={<></>} open={isModalOpen} footer={<></>} className="p-0 custom-modal rouder-31">
        <div className="relative bg-white border-[20px] border-[#0d5b94] rounded-[30px] pt-4">
          <div className="mb-1">
            <div className="h-[70px] w-full bg-[url('@assets/icon/VIETINBANK.png')] bg-contain bg-no-repeat mx-auto mt-[-10px] bg-center"></div>
          </div>

          <span className="absolute right-2 top-2">
            <IconCancel
              handleCancel={() => {
                handleCancel()
              }}
            />
          </span>
          <div className="border-2 border-[#6276a0] w-[85%] mx-auto">
            <img src={`${img?.vietQRImage}`} alt="" className="mx-auto w-full p-1 " />
          </div>

          <div className="mb-1">
            <p
              className="text-center text-[16px] font-[400] text-[#26538c] pb-1 mt-3"
            >Tên chủ TK: {img?.accountName}</p>
            <p className="text-center text-[16px] font-[600] text-[#26538c] pb-1">Số TK: {img?.accountNumber}</p>

            <p className="text-center text-[16px] font-[400] text-[#26538c] pb-3">Số tiền: {formatMoney(img?.amount)} VND</p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModaICB
